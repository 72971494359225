import React from "react";
import { DocuSignLogo } from "@ds/ui";

const LoadingSpinner = () => (
  <div className="loader">
    <div className="loader-logo">
      <DocuSignLogo />
    </div>
    <div className="loader-progress" />
  </div>
);

export default LoadingSpinner;
