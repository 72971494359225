import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Icon } from "@olive/react";
import { DocuSignLogo } from "@ds/ui";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import _ from "lodash";
import actions from "../actions";
import { withTranslations } from "../lib/TranslationProvider";
import { getLink } from "../lib/i18nlayer";

export const LanguageSelector = ({ visible, onChange, languages }) => (
  <div
    className={classNames("menu", { invisible: !visible })}
    style={{
      top: "auto",
      bottom: "20px",
      right: "0",
      left: "auto",
      height: "600px",
      overflow: "auto"
    }}
  >
    <div className="menu_list">
      {languages.map(lang => (
        <div className="menu_listItem" key={lang.code}>
          <button className="menu_item" onClick={() => onChange(lang.code)}>
            {lang.description}
          </button>
        </div>
      ))}
    </div>
  </div>
);

export const FooterLinks = ({ footerLinks }) => {
  const links = footerLinks && footerLinks.map((link, i) => link.show && (
    <li key={i}>
      <a
        href={link.url}
        className="footer-links_item"
        rel="noopener noreferrer"
        target="_blank"
      >
        {link.text}
      </a>
    </li>
  ));
  return <Fragment>{links}</Fragment>;
};

export class Footer extends React.Component {
  static langDescription(languages, language) {
    const lang = _.find(languages, l => l.code === language) || languages[0];
    return lang.description;
  }

  constructor(props) {
    super(props);
    this.state = {
      langSelectorVisible: false
    };
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }
  showMenu() {
    this.setState({ langSelectorVisible: true });
  }
  hideMenu() {
    if (this.state.langSelectorVisible) {
      window.setTimeout(() => {
        this.setState({ langSelectorVisible: false });
      }, 200);
    }
  }

  render() {
    const year = new Date().getFullYear();
    const {
      t,
      language,
      languages,
      onChangeLanguage,
      footerLinks
    } = this.props;
    return (
      <footer className="site_footer footer">
        <div className="footer_wrap">
          <div
            className="footer_left"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="footer-links" style={{ marginTop: "-1px" }}>
              {t("footer.powered_by")}
            </span>
            <div
              style={{
                marginLeft: "-7px",
                marginTop: "4px"
              }}
            >
              <DocuSignLogo />
            </div>
          </div>
          <div className="footer_right">
            <ul className="footer-links">
              <li style={{ position: "relative" }}>
                <button
                  className="footer-links_item link-plain btn-trigger"
                  type="button"
                  onBlur={this.hideMenu}
                  onFocus={this.showMenu}
                  onClick={this.showMenu}
                >
                  <Icon kind="language" />
                  {t("footer.change_language")} -{" "}
                  {Footer.langDescription(languages, language)}
                </button>
                <LanguageSelector
                  visible={this.state.langSelectorVisible}
                  onChange={onChangeLanguage}
                  languages={languages}
                />
              </li>
              <FooterLinks footerLinks={footerLinks} />
              <li>
                <a
                  href={getLink(
                    "https://www.docusign.com/company/terms-of-use",
                    language
                  )}
                  className="footer-links_item"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t("footer.terms_of_use")}
                </a>
              </li>
              <li>
                <a
                  href={getLink(
                    "https://www.docusign.com/company/privacy-policy",
                    language
                  )}
                  className="footer-links_item"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t("footer.privacy_policy")}
                </a>
              </li>
              <li>
                <span className="footer-links_item">
                  {t("footer.copyright", { year })}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  language: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
  })).isRequired
};

export const TranslatedFooter = withTranslations(Footer);

export default connect(
  null,
  dispatch =>
    bindActionCreators({ onChangeLanguage: actions.changeLocale }, dispatch)
)(TranslatedFooter);
