import React from 'react'
import lodash from "lodash";
import { Button, Header } from '@ds/ui'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslations } from '../lib/TranslationProvider'
import actions from '../actions'
import { getPowerFormId, getServer, getQuery } from '../reducers/context'
import { getBrandDetails, getIsSubmitButtonDisabled, getRecipients, getIsCollapsed, getErrorMessage, getAccountId } from '../reducers/powerform'
import { getErrorCode } from '../reducers'
import { getUserLocale } from '../lib/translate'
import { getLink } from '../lib/i18nlayer'

export const NDSEHeader = ({
  onClick,
  errorCode,
  t,
  isCollapsed,
  isSubmitButtonDisabled,
  ...brandingDetails
}) => (
  <Header>
    <Header.Left>
      <Header.Logo
        alt={brandingDetails.companyName || "DocuSign"}
        href="https://docusign.net"
        data-qa="logo-link"
      />
    </Header.Left>
    <Header.Right>
      { !errorCode && !isCollapsed &&
        <Button
          onClick={onClick}
          kind="primary"
          size="large"
          text={t("BeginSigningButtonText")}
          data-qa="dialog-submit-header"
          disabled={isSubmitButtonDisabled}
        />
      }
      <Header.ActionItem
        icon="help"
        href={getLink(
          "https://support.docusign.com/en/knowledgeSearch?by=topic&category=sign",
          getUserLocale()
        )}
        text={t("header.help")}
        data-qa="help-header"
      />
    </Header.Right>
  </Header>
);

const TranslatedNDSEHeader = withTranslations(NDSEHeader);

export default connect(
  state => ({
    server: getServer(state.context),
    powerformId: getPowerFormId(state.context),
    recipients: getRecipients(state.powerform),
    errorCode: getErrorCode(state),
    errorMessage: getErrorMessage(state),
    query: getQuery(state.context),
    isCollapsed: getIsCollapsed(state.powerform),
    isSubmitButtonDisabled: getIsSubmitButtonDisabled(state.powerform),
    ...getBrandDetails(state.powerform),
    accountId: getAccountId(state.powerform)
  }),
  dispatch =>
    bindActionCreators(
      {
        submitPowerform: actions.submitPowerform
      },
      dispatch
    ),
  (stateProps, dispatchProps) => {
    const {
      server,
      recipients,
      powerformId,
      query,
      ...otherStateProps
    } = stateProps;
    return {
      onClick: () =>
        dispatchProps.submitPowerform(server, recipients, powerformId, query),
      ...dispatchProps,
      ...otherStateProps
    };
  }
)(TranslatedNDSEHeader);
